import * as React from 'react'
import * as wagmi from 'wagmi'

import { useUser } from 'utils/user'
import { useMachine } from '@xstate/react'
import { MintType, mintMachine } from 'machines/mint.machine'
import { mintWithFP } from './rootApi'
import { useRouter } from 'next/router'

export function useMintMachine({
  bookId = '',
  firstEditionCollectionId = 0,
  secondEditionCollectionId = 0,
  firstEditionDurationInDays = 0,
  mintStartDate = new Date(),
  projectSlug = '',
  bookSlug = '',
}: {
  bookId?: string
  firstEditionCollectionId?: number
  secondEditionCollectionId?: number
  mintStartDate?: Date
  firstEditionDurationInDays?: number
  projectSlug?: string
  bookSlug?: string
}) {
  const { user } = useUser()
  const { data: signer } = wagmi.useSigner()
  const router = useRouter()

  const [state, send, services] = useMachine(mintMachine, {
    context: {
      bookId,
      firstEditionCollectionId,
      secondEditionCollectionId,
      firstEditionDurationInDays,
      mintStartDate,
      eoa: user?.eoa,
      futurepass: user?.futurepass,
      projectSlug,
      bookSlug,
    },
    services: {
      mint: async (c) => {
        if (!c.eoa || !c.futurepass || !signer) {
          throw Error('Not logged in')
        }

        await mintWithFP({
          collectionId:
            c.mintType === 'FIRST_EDITION'
              ? c.firstEditionCollectionId
              : c.secondEditionCollectionId,
          quantity: Number(c.mintQuantity),
          gas: c.gas,
          eoa: c.eoa,
          futurepass: c.futurepass,
          signer,
        })

        return null
      },
    },
  })

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const {
        payment_intent: paymentIntentId,
        payment_intent_client_secret: paymentIntentClientSecret,
        redirect_status: redirectSucceeded,
        futurepass,
        ownedQuantity,
        edition,
        collectionId,
        projectSlug,
        bookSlug,
        ...restQuery
      } = router.query

      if (paymentIntentId && paymentIntentClientSecret && redirectSucceeded) {
        send({
          type: 'OPEN_WITH_PAYMENT_INTENT',
          futurepass: futurepass as string,
          ownedQuantity: Number(ownedQuantity),
          edition: edition as MintType,
          collectionId: Number(collectionId),
          projectSlug: projectSlug as string,
          bookSlug: bookSlug as string,
        })

        router.replace(
          {
            pathname: router.pathname,
            query: restQuery,
          },
          undefined,
          { shallow: true }
        )
      }
    }
  }, [])

  React.useEffect(() => {
    services.subscribe((state) => {
      console.log(state)
    })
  }, [])

  React.useEffect(() => {
    if (user) {
      send({ type: 'LOGIN', eoa: user.eoa, futurepass: user.futurepass })
    }
  }, [user])

  return { state, send }
}
