import type { ModalProps } from 'components/modals/modal'

import Image from 'next/future/image'
import * as React from 'react'
import cx from 'classnames'

import { Button } from 'components/button'
import { Modal } from 'components/modals/modal'
import { LoadSpinner } from 'components/LoadSpinner'
import { StripePayment } from 'components/stripe-payment/stripe-payment'
import { Typography } from 'components/typography'

import { useUser } from 'utils/user'

import {
  MintMachineSender,
  MintMachineState,
  MintType,
  calcCast,
} from 'machines/mint.machine'

import styles from './modal.module.scss'
import { useRouter } from 'next/router'

export const MintModal = ({
  state,
  send,
}: {
  state: MintMachineState
  send: MintMachineSender
} & Omit<ModalProps, 'showDialog' | 'onClose'>) => {
  const { login } = useUser()

  return (
    <>
      <Modal
        ariaLabel="mint"
        disableOverlayClick
        onClose={() => {
          send({ type: 'CLOSE' })
        }}
        showDialog={state.hasTag('open')}
        className={styles.modal}
        containerClassName={styles.container}
      >
        {state.hasTag('no-user') && (
          <Button
            color="primary"
            onClick={() => {
              login()
              send({ type: 'CLOSE' })
            }}
          >
            Login
          </Button>
        )}

        {state.hasTag('details') && (
          <DetailsContent
            state={state}
            send={send}
            // collectionMinted={collectionMinted}
          />
        )}
        {state.hasTag('fiat') && <FiatPayment state={state} send={send} />}
        {state.hasTag('mint') && <Minting />}
        {state.hasTag('success') && <Success state={state} send={send} />}
      </Modal>
    </>
  )
}

const DetailsContent = ({
  state,
  send,
  collectionMinted,
}: {
  state: MintMachineState
  send: MintMachineSender
  collectionMinted?: number
}) => {
  const benefits =
    state.context.mintType === 'FIRST_EDITION'
      ? [
          `1st edition copy of Machines of Mana - ${state.context.title}`,
          'Chance to obtain an ultra-rare edition',
          'Receive early access to upcoming releases',
          'Special traits which are only available in the 1st Edition',
        ]
      : [
          `2nd edition copy of Machines of Mana - ${state.context.title}`,
          'Chance to obtain an ultra-rare edition',
        ]

  const { crypto } = calcCast(state.context)

  const imageSrc = `/images/${state.context.bookSlug}-${
    state.context.mintType === 'FIRST_EDITION' ? '1st' : '2nd'
  }-edition.jpg`

  return (
    <>
      <div className={styles.title}>
        <Typography variant="sub-heading-1" noMargins>
          Minting details
        </Typography>
      </div>
      <div className={styles.details__grid}>
        <div className={styles.details__image}>
          <Image
            src={imageSrc}
            width={140}
            height={215}
            alt={state.context.title}
          />
        </div>
        <Typography variant="body-regular" noMargins>
          Item
        </Typography>
        <Typography variant="body-small" color="sky-dark" noMargins>
          {state.context.title} ·
          {state.context.mintType === 'FIRST_EDITION'
            ? ' 1st Edition'
            : ' 2nd Edition'}
        </Typography>
        <Typography variant="body-regular" noMargins>
          Benefits
        </Typography>
        <ul className={styles.benefits}>
          {benefits.map((benefit, index) => (
            <li key={`${index}-benefit`}>{benefit}</li>
          ))}
        </ul>
        <div className={styles.details__divider} />
        <Typography variant="body-regular" noMargins>
          Price per item
        </Typography>
        <Typography variant="body-small" color="teal-light" noMargins>
          {state.context.mintCost} ROOT
        </Typography>
        <Typography variant="body-regular" noMargins>
          Quantity
        </Typography>
        <div>
          <div className={styles.numberInput}>
            <Button
              style="unstyled"
              fitContent
              onClick={() => {
                send({ type: 'SET_MINT_QUANTITY', value: 'DEC' })
              }}
            >
              <svg width={25} height={25}>
                <use
                  href="/svgs/icon-sprites.svg#minus"
                  xlinkHref="/svgs/icon-sprites.svg#minus"
                />
              </svg>
            </Button>
            <Typography variant="sub-heading-1" noMargins>
              {state.context.mintQuantity}
            </Typography>
            <Button
              style="unstyled"
              fitContent
              onClick={() => {
                send({ type: 'SET_MINT_QUANTITY', value: 'INC' })
              }}
            >
              <svg width={25} height={25}>
                <use
                  href="/svgs/icon-sprites.svg#plus"
                  xlinkHref="/svgs/icon-sprites.svg#plus"
                />
              </svg>
            </Button>
          </div>
          <Typography variant="body-small" color="sky-dark" noBottomMargin>
            Wallet balance:{' '}
            <span
              className={cx({
                // @ts-ignore
                [styles.error]: state.context.insufficientRootFunds,
              })}
            >
              {state.context.root}
            </span>{' '}
            ROOT
          </Typography>
        </div>
      </div>
      <div className={styles.details__total}>
        <Typography variant="body-regular" noMargins>
          Total
        </Typography>
        <div className={styles.details__price}>
          <Typography variant="body-regular" noMargins>
            {crypto} ROOT + ~{state.context.gasFee} {state.context.gas}
          </Typography>
        </div>
      </div>
      <div className={styles.gas}>
        <Typography variant="body-regular" noMargins>
          Pay gas with
        </Typography>
        <div className={styles.gas__actions}>
          <Button
            color={state.context.gas === 'ROOT' ? 'primary' : 'secondary'}
            onClick={() => {
              send({ type: 'SET_GAS', value: 'ROOT' })
            }}
          >
            ROOT
          </Button>
          <Button
            color={state.context.gas === 'XRP' ? 'primary' : 'secondary'}
            onClick={() => {
              send({ type: 'SET_GAS', value: 'XRP' })
            }}
          >
            XRP
          </Button>
        </div>
      </div>
      <div className={styles.details__row}>
        <Button
          iconPosition="left"
          onClick={() => {
            send({ type: 'MINT' })
          }}
          className={styles.cta}
          isDisabled={
            state.hasTag('build-tx') || state.context.insufficientRootFunds
          }
        >
          <Image
            src="/logos/root-circle.webp"
            width={16}
            height={16}
            alt="ROOT"
            className={styles.asto}
          />
          Pay with ROOT
        </Button>
        <Button
          onClick={() => {
            send({ type: 'FIAT' })
          }}
        >
          Pay with fiat
        </Button>
      </div>
    </>
  )
}

const FiatPayment = ({
  state,
  send,
}: {
  state: MintMachineState
  send: MintMachineSender
}) => {
  if (state.hasTag('init')) {
    return <LoadSpinner />
  }

  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  return (
    <StripePayment
      clientSecret={state.context.stripeClientSecret!}
      futurepass={state.context.futurepass!}
      ownedQuantity={state.context.ownedQuantity!}
      edition={state.context.mintType as MintType}
      collectionId={
        state.context.mintType! === 'FIRST_EDITION'
          ? state.context.firstEditionCollectionId!
          : state.context.secondEditionCollectionId
      }
      bookSlug={state.context.bookSlug}
      projectSlug={state.context.projectSlug}
      fiatCost={state.context.fiatCost}
      send={send}
    />
  )
}

const Minting = () => {
  return (
    <div className={styles.minting}>
      <Typography variant="heading-3" noMargins>
        Please wait
      </Typography>
      <Typography variant="body-regular" className={styles.mintMessage}>
        Please do not close this window. <br />
        Your payment is being processed.
      </Typography>
      <LoadSpinner />
    </div>
  )
}

const Success = ({
  state,
  send,
}: {
  state: MintMachineState
  send: MintMachineSender
}) => {
  const router = useRouter()

  const { projectSlug, bookSlug } = state.context
  const viewComicTo =
    projectSlug && bookSlug
      ? `/project/${projectSlug}/${bookSlug}`
      : '/bookshelf'

  const samePath = router.asPath === viewComicTo

  return (
    <div className={styles.minting}>
      <Typography variant="heading-3" noMargins>
        Transaction successful
      </Typography>
      <div className={styles['success-actions']}>
        {!samePath ? (
          <Button as="link" to={viewComicTo}>
            View comic
          </Button>
        ) : (
          <Button
            onClick={() => {
              send({ type: 'CLOSE' })
            }}
          >
            View comic
          </Button>
        )}
        {state.context.title ? (
          <Button
            color="secondary"
            onClick={() => {
              send({ type: 'BACK' })
            }}
          >
            Mint more
          </Button>
        ) : null}
      </div>
    </div>
  )
}
