import { createMachine, assign, Sender, StateFrom } from 'xstate'

import { getMintWithFPTx, ownedTokens, queryRoot } from 'utils/rootApi'
import { client } from 'utils/trpc'
import { SubmittableExtrinsic } from '@polkadot/api/types'
import { isMintLive } from 'utils/utils'

export type MintType = 'FIRST_EDITION' | 'SECOND_EDITION'

type Gas = 'ROOT' | 'XRP'

type MintContext = {
  eoa?: string
  futurepass?: string

  // Collection values
  title: string
  bookSlug: string
  bookId: string
  firstEditionCollectionId: number
  secondEditionCollectionId: number
  mintCost: number
  fiatCost: number
  mintStartDate: Date
  firstEditionDurationInDays: number
  projectSlug: string
  secondEditionMintStartDate: Date | null

  root: string
  insufficientRootFunds: boolean

  // Mint values
  mintType?: MintType | false
  mintQuantity: string
  gas: Gas
  gasFee: string
  tx?: SubmittableExtrinsic<'promise', any>

  // Owned
  ownedQuantity: number | undefined

  // Fiat values
  stripeClientSecret: string | null
}

type Events =
  | { type: 'LOGIN'; eoa: string; futurepass: string }
  | { type: 'BACK' }
  | { type: 'CRYPTO' }
  | { type: 'FIAT' }
  | { type: 'MINT' }
  | { type: 'SET_MINT_QUANTITY'; value: string }
  | { type: 'SET_GAS'; value: Gas }
  | { type: 'SUCCESS' }
  | { type: 'FAILURE' }
  | {
      type: 'OPEN_WITH_PAYMENT_INTENT'
      futurepass: string
      ownedQuantity: number
      edition: MintType
      collectionId: number
      bookSlug: string
      projectSlug: string
    }
  | { type: 'CLOSE' }
  | {
      type: 'SET_BOOK'
      title: string
      bookSlug: string
      bookId: string
      firstEditionCollectionId: number
      secondEditionCollectionId: number
      mintCost: number
      fiatCost: number
      mintStartDate: Date
      secondEditionMintStartDate: Date | null
      firstEditionDurationInDays: number
      projectSlug: string
    }

type Services = {
  getData: {
    data: {
      root: string
      mintType: MintType | false
      ownedQuantity: number | undefined
      stripeClientSecret: string | null
    }
  }
  mint: { data: null }
  polling: { data: void }
  sync: { data: null }
  buildTx: {
    data: {
      tx: SubmittableExtrinsic<'promise', any>
      gasFee: string
      insufficientRootFunds: boolean
      quantity: number
    }
  }
}

export const mintMachine = createMachine(
  {
    tsTypes: {} as import('./mint.machine.typegen').Typegen0,
    id: 'Mint Machine',
    predictableActionArguments: true,
    schema: {
      context: {} as MintContext,
      events: {} as Events,
      services: {} as Services,
    },
    context: {
      eoa: undefined,
      futurepass: undefined,

      title: '',
      bookSlug: '',
      bookId: '',
      projectSlug: '',
      firstEditionCollectionId: 0,
      secondEditionCollectionId: 0,
      firstEditionDurationInDays: 0,
      mintCost: 500,
      fiatCost: 25,
      mintStartDate: new Date(),
      secondEditionMintStartDate: new Date(),

      root: '',
      insufficientRootFunds: false,

      mintType: undefined,
      mintQuantity: '1',
      gas: 'XRP',
      tx: undefined,
      gasFee: '',

      ownedQuantity: undefined,

      stripeClientSecret: null,
    },
    on: {
      LOGIN: {
        actions: ['setUser'],
      },
      CLOSE: {
        target: 'Closed',
      },
    },
    initial: 'Closed',
    states: {
      Closed: {
        tags: ['closed'],
        on: {
          OPEN_WITH_PAYMENT_INTENT: {
            actions: ['setWithPaymentIntent'],
            target: 'Verify',
          },
          SET_BOOK: [
            {
              cond: 'has user',
              actions: ['setBook'],
              target: 'Get Data',
            },
            { target: 'No User' },
          ],
        },
      },
      'No User': {
        tags: ['no-user', 'open'],
      },
      'Get Data': {
        tags: ['init', 'open', 'fiat'],
        invoke: {
          id: 'get-data',
          src: 'getData',
          onDone: [
            {
              cond: 'no mint',
              target: 'Closed',
            },
            {
              actions: ['setInitialData'],
              target: 'Fiat',
            },
          ],
          onError: {},
        },
      },
      Crypto: {
        tags: ['details', 'open'],
        on: {
          SET_MINT_QUANTITY: {
            actions: ['setMintQuantity'],
            target: 'Build Tx',
          },
          SET_GAS: {
            actions: ['setGas'],
            target: 'Build Tx',
          },
          FIAT: {
            target: 'Fiat',
          },
          MINT: {
            target: 'Mint',
          },
        },
      },
      'Build Tx': {
        tags: ['build-tx', 'details', 'open'],
        invoke: {
          id: 'build-tx',
          src: 'buildTx',
          onDone: [
            {
              cond: 'correct tx',
              actions: ['setTxDetails'],
              target: 'Crypto',
            },
          ],
          onError: {},
        },
        on: {
          SET_MINT_QUANTITY: {
            actions: ['setMintQuantity'],
            target: '.',
            internal: false,
          },
          SET_GAS: {
            actions: ['setGas'],
            target: '.',
            internal: false,
          },
        },
      },
      Mint: {
        tags: ['mint', 'open'],
        invoke: {
          id: 'mint',
          src: 'mint',
          onDone: {
            target: 'Verify',
          },
          onError: {
            target: 'Crypto',
          },
        },
      },
      Fiat: {
        tags: ['fiat', 'open'],
        on: {
          CRYPTO: {
            target: 'Build Tx',
          },
        },
      },
      Verify: {
        tags: ['mint', 'verify', 'open'],
        invoke: {
          id: 'polling',
          src: 'polling',
        },
        on: {
          SUCCESS: {
            target: 'Success',
          },
          // FAILURE: {
          //   target: 'Failure',
          // },
        },
      },
      Success: {
        tags: ['success', 'open'],
        exit: ['clearMint'],
        invoke: {
          id: 'sync',
          src: 'sync',
        },
        on: {
          BACK: {
            target: 'Get Data',
          },
        },
      },
      Failure: {
        tags: ['failure', 'open'],
      },
    },
  },
  {
    actions: {
      setUser: assign((_c, e) => {
        return {
          eoa: e.eoa,
          futurepass: e.futurepass,
        }
      }),
      setWithPaymentIntent: assign((_c, e) => {
        if (e.edition === 'FIRST_EDITION') {
          return {
            futurepass: e.futurepass,
            ownedQuantity: e.ownedQuantity,
            mintType: e.edition,
            firstEditionCollectionId: e.collectionId,
            projectSlug: e.projectSlug,
            bookSlug: e.bookSlug,
          }
        }

        return {
          futurepass: e.futurepass,
          ownedQuantity: e.ownedQuantity,
          mintType: e.edition,
          secondEditionCollectionId: e.collectionId,
          projectSlug: e.projectSlug,
          bookSlug: e.bookSlug,
        }
      }),
      setInitialData: assign((_c, e) => {
        return e.data
      }),
      setMintQuantity: assign((c, e) => {
        if (e.value === 'INC') {
          const newQuantity = Number(c.mintQuantity) + 1
          return {
            mintQuantity: newQuantity.toString(),
          }
        } else if (e.value === 'DEC') {
          let newQuantity = Number(c.mintQuantity)
          if (newQuantity > 1) {
            newQuantity--
            return {
              mintQuantity: newQuantity.toString(),
            }
          }
          return {}
        }
        const newQuantity = Number(e.value)
        if (!Number.isNaN(newQuantity)) {
          return {
            mintQuantity: e.value,
          }
        }
        return {}
      }),
      setGas: assign((c, e) => {
        return {
          gas: e.value,
        }
      }),
      setTxDetails: assign((c, e) => {
        return {
          tx: e.data.tx,
          gasFee: e.data.gasFee,
          insufficientRootFunds: e.data.insufficientRootFunds,
        }
      }),
      clearMint: assign(() => {
        return {
          mintQuantity: '1',
          ownedQuantity: 0,
        }
      }),
      setBook: assign((_c, e) => {
        return {
          title: e.title,
          bookSlug: e.bookSlug,
          projectSlug: e.projectSlug,
          bookId: e.bookId,
          firstEditionCollectionId: e.firstEditionCollectionId,
          secondEditionCollectionId: e.secondEditionCollectionId,
          mintCost: e.mintCost,
          mintStartDate: e.mintStartDate,
          secondEditionMintStartDate: e.secondEditionMintStartDate,
          firstEditionDurationInDays: e.firstEditionDurationInDays,
          fiatCost: e.fiatCost,
        }
      }),
    },
    guards: {
      'has user': (c) => {
        return Boolean(c.eoa && c.futurepass)
      },
      'no mint': (c) => {
        return c.mintType === false
      },
      'correct tx': (c, e) => {
        return Number(c.mintQuantity) === e.data.quantity
      },
    },
    services: {
      getData: async (c, _e) => {
        if (!c.futurepass) {
          throw Error('User not connected')
        }

        const root = await queryRoot(c.futurepass)

        const mintType = isMintLive({
          firstEditionMintStartDate: c.mintStartDate,
          firstEditionDurationInDays: c.firstEditionDurationInDays,
          secondEditionMintStartDate: c.secondEditionMintStartDate,
        })

        if (mintType === false) {
          return {
            root,
            mintType,
            ownedQuantity: undefined,
            stripeClientSecret: null,
          }
        }

        const ownedTokensPromise = ownedTokens({
          collectionId:
            mintType === 'FIRST_EDITION'
              ? c.firstEditionCollectionId
              : c.secondEditionCollectionId,
          address: c.futurepass,
        })
        const paymentIntentPromise = client.mutation('mint.stripeMint', {
          bookId: c.bookId,
          collectionId:
            mintType === 'FIRST_EDITION'
              ? c.firstEditionCollectionId
              : c.secondEditionCollectionId,
          quantity: 1,
        })

        const [{ quantity }, { clientSecret }] = await Promise.all([
          ownedTokensPromise,
          paymentIntentPromise,
        ])

        return {
          root,
          mintType,
          ownedQuantity: quantity,
          stripeClientSecret: clientSecret,
        }
      },

      buildTx: async (c) => {
        if (!c.eoa || !c.futurepass) {
          throw Error('Not logged in')
        }

        const quantity = Number(c.mintQuantity)
        const { tx, gasFee } = await getMintWithFPTx({
          collectionId:
            c.mintType === 'FIRST_EDITION'
              ? c.firstEditionCollectionId
              : c.secondEditionCollectionId,
          quantity,
          gas: c.gas,
          eoa: c.eoa,
          futurepass: c.futurepass,
        })

        const { crypto } = calcCast(c)

        const totalRootCost =
          c.gas === 'ROOT' ? crypto + Number(gasFee) : crypto
        const insufficientRootFunds = totalRootCost > Number(c.root)

        return {
          tx,
          gasFee,
          insufficientRootFunds,
          quantity,
        }
      },

      polling: (c) => (send) => {
        if (!c.futurepass) {
          throw Error('Not logged in')
        }

        polling({
          futurepass: c.futurepass,
          collectionId:
            c.mintType === 'FIRST_EDITION'
              ? c.firstEditionCollectionId
              : c.secondEditionCollectionId,
          ownedBeforeMint: c.ownedQuantity ?? 0,
          attempt: 0,
        })
          .then(() => {
            send({ type: 'SUCCESS' })
          })
          .catch(() => {
            send({ type: 'FAILURE' })
          })
      },

      sync: async (c) => {
        await client.mutation('mint.sync', {
          collectionId:
            c.mintType === 'FIRST_EDITION'
              ? c.firstEditionCollectionId
              : c.secondEditionCollectionId,
        })

        return null
      },
    },
  }
)

export type MintMachineSender = Sender<Events>
export type MintMachineState = StateFrom<typeof mintMachine>

export const calcCast = (c: MintContext) => {
  const quantity = Number(c.mintQuantity)

  return {
    crypto: quantity * c.mintCost,
  }
}

const polling = async ({
  futurepass,
  collectionId,
  ownedBeforeMint,
  attempt,
}: {
  futurepass: string
  collectionId: number
  ownedBeforeMint: number
  attempt: number
}): Promise<boolean> => {
  const ownedTokensRes = await ownedTokens({
    collectionId,
    address: futurepass,
  })

  if (ownedTokensRes.quantity > ownedBeforeMint) {
    return true
  }

  await new Promise((res, rej) => {
    setTimeout(() => {
      res(null)
    }, 15000)
  })

  return await polling({
    futurepass,
    collectionId,
    ownedBeforeMint,
    attempt: attempt + 1,
  })
}
