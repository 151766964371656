import Image from 'next/image'
import styles from './LoadSpinner.module.scss'

export const LoadSpinner = ({ size = 56 }: { size?: 56 | 28 }) => {
  return (
    <div className={styles.spinnerContainer}>
      <Image
        src="/svgs/spinner.svg"
        width={size}
        height={size}
        className={styles.spinner}
        alt={'spinner'}
      />
    </div>
  )
}
