import type { MintMachineSender, MintType } from 'machines/mint.machine'
import * as React from 'react'
import {
  loadStripe,
  type StripePaymentElementOptions,
  type StripeElementsOptions,
} from '@stripe/stripe-js'
import {
  Elements,
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { LoadSpinner } from 'components/LoadSpinner'
import { Button } from 'components/button'

import styles from './stripe-payment.module.scss'
import { Typography } from 'components/typography'

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK ?? '')

type StripePaymentProps = {
  clientSecret: string
  futurepass: string
  ownedQuantity: number
  edition: MintType
  collectionId: number
  bookSlug: string
  projectSlug: string
  fiatCost: number
  send: MintMachineSender
}

export function StripePayment({
  clientSecret,
  futurepass,
  ownedQuantity,
  edition,
  collectionId,
  bookSlug,
  projectSlug,
  fiatCost,
  send,
}: StripePaymentProps) {
  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'night',
    },
  }

  return (
    <Elements options={options} stripe={stripePromise}>
      <div className={styles.title}>
        <Typography variant="sub-heading-1" noMargins>
          Payment Details
        </Typography>
        <Typography variant="body-regular" noMargins>
          {fiatCost || 20} NZD
        </Typography>
      </div>
      <Checkout
        futurepass={futurepass}
        ownedQuantity={ownedQuantity}
        edition={edition}
        collectionId={collectionId}
        bookSlug={bookSlug}
        projectSlug={projectSlug}
        send={send}
      />
    </Elements>
  )
}

function Checkout({
  futurepass,
  ownedQuantity,
  edition,
  collectionId,
  bookSlug,
  projectSlug,
  send,
}: Pick<
  StripePaymentProps,
  | 'futurepass'
  | 'ownedQuantity'
  | 'edition'
  | 'collectionId'
  | 'send'
  | 'bookSlug'
  | 'projectSlug'
>) {
  const stripe = useStripe()
  const elements = useElements()

  const [submitting, setSubmitting] = React.useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!stripe || !elements) {
      console.error('Stripe.js has not yet loaded.')
      return
    }

    setSubmitting(true)

    const redirectToUrl = new URL(window.location.href)
    redirectToUrl.searchParams.set('futurepass', futurepass)
    redirectToUrl.searchParams.set('ownedQuantity', ownedQuantity.toString())
    redirectToUrl.searchParams.set('edition', edition)
    redirectToUrl.searchParams.set('collectionId', collectionId.toString())
    redirectToUrl.searchParams.set('projectSlug', projectSlug)
    redirectToUrl.searchParams.set('bookSlug', bookSlug)

    await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: redirectToUrl.toString(),
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    setSubmitting(false)
  }

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
    },
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element" />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <br />
      <div className={styles.container}>
        <Button isDisabled={submitting || !stripe || !elements} type="submit">
          {submitting ? <LoadSpinner size={28} /> : 'Pay now'}
        </Button>
        <Button
          style="outline"
          isDisabled={submitting || !stripe || !elements}
          fitContent
          onClick={() => {
            send({ type: 'CRYPTO' })
          }}
        >
          {submitting ? <LoadSpinner size={28} /> : 'Crypto'}
        </Button>
      </div>
    </form>
  )
}
