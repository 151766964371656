// src/utils/trpc.ts
import superjson from 'superjson'
import type { AppRouter } from '../server/router'
import { createReactQueryHooks } from '@trpc/react'
import { createTRPCClient } from '@trpc/client'
import { getBaseUrl } from './config'

export const trpc = createReactQueryHooks<AppRouter>()

/**
 * Check out tRPC docs for Inference Helpers
 * https://trpc.io/docs/infer-types#inference-helpers
 */

// Vanilla client. can be used without hooks. ideal for reusable helper functions
export const client = createTRPCClient<AppRouter>({
  url: `${getBaseUrl()}/api/trpc`,
  transformer: superjson,
})
