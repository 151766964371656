import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'
import { Typography } from 'components/typography'
import { WithChildren } from 'types'
import cx from 'classnames'

import styles from './modal.module.scss'

export type ModalProps = WithChildren<{
  onClose?: () => void
  disableOverlayClick?: true
  showDialog: boolean
  /**
   * ID is to accessibly identify the modal
   * and must be provided if there is a title
   */
  id?: string
  title?: string
  hideCloseButton?: boolean
  /**Only required if the modal has
   *  no title, and used to label the modal
   */
  ariaLabel?: string
  className?: string
  disableClose?: boolean
  containerClassName?: string
}>

export const Modal = ({
  showDialog,
  onClose,
  disableOverlayClick,
  children,
  id,
  title,
  hideCloseButton,
  ariaLabel,
  className,
  disableClose,
  containerClassName,
}: ModalProps) => {
  return (
    <DialogOverlay
      className={cx(styles.modal, showDialog && styles.open)}
      isOpen={showDialog}
      onDismiss={!disableOverlayClick ? onClose : undefined}
    >
      <DialogContent
        aria-label={id || ariaLabel}
        className={cx(
          styles.modal__container,
          showDialog && styles.open__container,
          containerClassName
        )}
      >
        {onClose ? (
          <button
            className={cx(styles.close, {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              [styles.noClose!]: hideCloseButton,
            })}
            onClick={onClose}
            aria-label="close"
            disabled={disableClose}
          >
            <svg width="24" height="24" aria-hidden="true">
              <use
                href="/svgs/icon-sprites.svg#close"
                xlinkHref="/svgs/icon-sprites.svg#close"
              />
            </svg>
          </button>
        ) : undefined}
        <div className={cx(styles.modal__content, className)}>
          {title ? (
            <Typography variant="heading-2" component="h1" id={id} noMargins>
              {title}
            </Typography>
          ) : null}
          {children}
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}
